import { Amplify } from "aws-amplify";

import awsConfig from "./src/aws-exports";
import React from "react";
import { Prism } from "prism-react-renderer";

// Import additional Prism languages
(typeof global !== "undefined" ? global : window).Prism = Prism;
require("prismjs/components/prism-java");

Amplify.configure(awsConfig);
